var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('p', [_c('strong', [_vm._v("Info: ")]), _c('span', [_vm._v("This layout can be useful for getting started with empty content section.")])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }